/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import React from 'react'
import { get, isFunction, compact } from 'lodash'
import { graphql } from 'gatsby'

import Credits from '../components/credits'
import Introduction from '../components/introduction'
import { InlineHeader } from '../components/page-header'
import PageWrapper from '../components/page-wrapper'
import ProductionBlocks from '../components/production-blocks'
import Section from '../components/section'
import Title from '../components/title'
import CenterCol from '../components/center-col'
import LongForm from '../components/long-form'
import Signposts, { mapSignpost } from '../components/signposts'
import PostPage from '../components/post-page'

import {
  Cont,
  TextBlock,
  FlexCols,
  FlexCol,
  Space,
  FlexColEmpty,
} from '../styles/els'

import {
  formatImage,
  renderHTML,
  formatDate,
  resolveButtons,
} from '../lib/helpers'
import { FullsizeImage, InlineImage } from '../lib/fragments'

import { setValue, useThemeUISetValue } from '../styles/utils'

const mapToCTA = (cta) => {
  const { text, image, buttons: _buttons } = cta

  return {
    heading: 'Get involved',
    text,
    buttons: resolveButtons(_buttons),
    image: image ? formatImage(image) : null,
    display: 'block',
  }
}

const Video = (props) => {
  const { data } = props

  const title = get(data, 'video.title')
  const id = get(data, 'video.id')
  const pageColor = get(data, 'video.acfVideo.pageColour')

  const video = get(data, 'video.acfVideo.video')
  const introduction = get(data, 'video.acfVideo.intro')
  const longFormContent = get(data, 'video.acfVideo.longForm')

  return (
    <PostPage
      pageColor={pageColor}
      headerVideo={video}
      title={title}
      introduction={introduction}
      longFormContent={longFormContent}
      relatedCards={{
        type: 'videos',
        currentId: id,
      }}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    video: wpVideo(id: { eq: $id }) {
      title
      uri
      id
      acfVideo {
        pageColour
        thumbnail {
          ...FullsizeImage
        }
        video {
          videoType
          mux
          embed
        }
        intro
        longForm {
          __typename
          ... on WpVideo_Acfvideo_LongForm_Text {
            text
          }
        }
      }
    }
  }
`

export default Video
